























import { Component, Vue, ModelSync, Prop } from "vue-property-decorator";

@Component
export default class HelpDialog extends Vue {
    @ModelSync("shownValue", "change", { type: Boolean })
    shown!: boolean;

    @Prop({ type: String, required: true, default: "" })
    htmlContent!: string;
}
